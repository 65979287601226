import React from "react";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa6";
import { Pagination, PaginationItemType, Link, Button } from "@nextui-org/react";

var _ = require('lodash');

export default function App(params) {
    let asPath = params.asPath;
    if (!asPath.includes('page')) {
        asPath += asPath.includes('?') ? '&' : '?' + 'page=1';
    }

    const renderItem = ({ ref, key, value, isActive, onNext, onPrevious, setPage, className }) => {
        if (value === PaginationItemType.NEXT) {
            let nextPage = params.pageData.currentPage === params.pageData.pageCount ? params.pageData.pageCount : params.pageData.currentPage + 1;
            return (<>
                <Button isDisabled={params.pageData.currentPage === params.pageData.pageCount} key={key}
                    className="rounded-none bg-none px-0 min-w-unit-10 h-unit-9 bg-white" onClick={onNext}>
                    <Link href={_.replace(asPath, /page=\d+/, `page=${nextPage}`)} className="text-black">
                        <FaChevronRight />
                    </Link>
                </Button>
            </>);
        }

        if (value === PaginationItemType.PREV) {
            let prevPage = params.pageData.currentPage - 1 || 1;
            return (
                <>
                    <Button isDisabled={params.pageData.currentPage === 1} key={key}
                        className="rounded-none bg-none px-0 min-w-unit-10 h-unit-9 bg-white" onClick={onPrevious}>
                        <Link href={_.replace(asPath, /page=\d+/, `page=${prevPage}`)} className="text-black">
                            <FaChevronLeft />
                        </Link>
                    </Button>
                </>
            );
        }

        if (value === PaginationItemType.DOTS) {
            return <button key={key} className={className}>...</button>;
        }

        // cursor is the default item
        return (
            <Link className={isActive ? 'bg-primary' : 'bg-gray-300'}
                href={_.replace(asPath, /page=\d+/, `page=${value}`)}
                >
                <button
                    key={key}
                    ref={ref}
                    className={_.concat(
                    className,
                    isActive &&
                        "text-white font-bold",
                    )}
                    onClick={() => setPage(value)}
                >
                    {value}
                </button>
            </Link>
        );
    };

    return (
        <Pagination
            showControls
            radius="full"
            variant="light"
            initialPage={1}
            className="gap-2"
            disableCursorAnimation
            renderItem={renderItem}
            total={params.pageData.pageCount}
            page={params.pageData.currentPage}
        />
    );
}
