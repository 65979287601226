import React from "react";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa6";
import { Pagination, PaginationItemType, Link, Button } from "@nextui-org/react";

var _ = require('lodash');

export default function PaginationCustomAjax(params) {
    const asPath = params.asPath.includes('page') ? params.asPath : params.asPath + '?page=1';
    const renderItem = ({ ref, key, value, isActive, onNext, onPrevious, setPage, className }) => {

        if (value === PaginationItemType.NEXT) {
            let nextPage = params.pageData.currentPage === params.pageData.pageCount ? params.pageData.pageCount : params.pageData.currentPage + 1;

            return (<>
                <Button isDisabled={params.pageData.currentPage === params.pageData.pageCount} key={key}
                    className="rounded-none bg-none px-0 min-w-unit-10 h-unit-9 bg-secondary"
                    onClick={
                        () => {
                            onNext,
                            params.actions.setCurrentPage(nextPage)
                        }
                    }
                >
                    <FaChevronRight className="text-black" />
                </Button>
            </>);
        }

        if (value === PaginationItemType.PREV) {
            let prevPage = params.pageData.currentPage - 1 || 1;

            return (
                <>
                    <Button isDisabled={params.pageData.currentPage === 1} key={key}
                        className="rounded-none bg-none px-0 min-w-unit-10 h-unit-9 bg-secondary"
                        onClick={() => {
                            onPrevious,
                            params.actions.setCurrentPage(prevPage)
                        }}>
                        <FaChevronLeft />
                    </Button>
                </>
            );
        }

        if (value === PaginationItemType.DOTS) {
            return <button key={key} className={className}>...</button>;
        }

        // cursor is the default item
        return (
            <div className={isActive ? ' bg-primary' : ' bg-secondary'}>
                <button
                    key={key} ref={ref}
                    className={_.concat(
                    className,
                    isActive &&
                        "text-white font-bold bg-primary",
                    )}
                    onClick={() => {
                        setPage(value);
                        params.actions.setCurrentPage(value)
                    }}
                >
                    {value}
                </button>
            </div>
        );
    };

    return (
        <Pagination
            showControls
            radius="full"
            variant="light"
            initialPage={1}
            className="gap-2"
            disableCursorAnimation
            renderItem={renderItem}
            total={params.pageData.pageCount}
            page={params.pageData.currentPage}
        />
    );
}
